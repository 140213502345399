<template>
  <div id="app" class="crt" style="padding: 15px">
    <el-row>
      <el-col :span="24">
        <el-page-header @back="goSelect" content="CRT图片管理">
        </el-page-header>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12" :offset="0">
        <el-button type="success" size="default" @click="dialogVisible = true"
          >添加图片信息</el-button
        >
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="24" :offset="0">
        <el-table
          v-loading="loading"
          :data="crtList"
          :stripe="true"
          :border="true"
          style="width: 100%"
          height="500px"
        >
          <el-table-column
            type="index"
            label="编号"
            align="center"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="CRT名称"
            align="center"
          ></el-table-column>
          <el-table-column label="图片" align="center">
            <template slot-scope="scope">
              　<img :src="baseUrl + scope.row.url" width="200" />
            </template>
          </el-table-column>

          <el-table-column
            prop="ciId"
            label="公司"
            align="center"
          ></el-table-column>

          <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleEdit(scope.row)"
                type="primary"
                size="small"
                >编辑</el-button
              >
              <el-button
                @click="removeCrtInfo(scope.row.id)"
                type="danger"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-pagination
      :current-page.sync="current"
      :page-size="limit"
      :total="total"
      layout="total, prev, pager, next, jumper"
      style="padding-top: 10px;  text-align: center"
      @current-change="getCrtList"
    ></el-pagination>

    <el-dialog
      title="CRT信息"
      :visible.sync="dialogVisible"
      width="30%"
      @close="clearCrtInfo"
    >
      <el-form label-width="120px" :model="crtInfo">
        <el-form-item label="CRT名称">
          <el-input v-model="crtInfo.name" />
        </el-form-item>

        <el-form-item label="绑定公司">
          <el-cascader
            placeholder="请选择需要绑定的公司"
            :show-all-levels="false"
            clearable
            ref="cascader"
            v-model="crtInfo.ciId"
            :options="allCompanyList"
            @change="checkedCompany"
            :props="defaultProps"
            style="width: 100%"
          >
          </el-cascader>
        </el-form-item>

        <el-form-item label="CRT图片" prop="val">
          <input
            v-if="addOrEditFlag == 'add'"
            id="crtInfoPicture"
            type="file"
            ref="uploadInput"
            @change="fileChange"
          />
          <span v-if="addOrEditFlag == 'add'"
            >上传图标只能是 JPG、PNG或SVG 格式,并且大小不超过100M</span
          >
          <img v-if="imgFlag" :src="crtInfo.url" style="width: 300px" />
          <img
            v-if="addOrEditFlag == 'edit' && !imgFlag"
            :src="baseUrl + crtInfo.url"
            style="width: 300px"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrEditCrtInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CRT from "@/api/manageApi/CrtInfo.js";
import BaseData from "@/assets/config/BaseData.js";
import companySystem from "@/api/managementApi/CompanyInfo";

export default {
  data() {
    return {
      companyNameById: {},
      allCompanyList: [], // 绑定公司
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
      },

      //分页相关数据
      current: 1, //当前页
      limit: 5, //每页显示条数
      total: 0, //数据总数

      //页面数据
      crtList: [], //CRT集合

      crtInfo: {
        //当前编辑或添加的CRT信息
        id: "",
        name: "",
        url: "",
        ciId: "",
      },
      crtQuery: {
        //模糊查询条件对象
        name: "",
      },

      //图片虚拟路径前缀，用于访问服务器以获取图片
      baseUrl: BaseData.baseUrl,

      //图片上传
      file: {},

      //标志位
      loading: true,
      dialogVisible: false, //添加、编辑弹框的标志位
      dialogImageVisible: false, //图片预览
      addOrEditFlag: "add", //添加、编辑标志位 add添加  edit编辑
      imgFlag: false,
    };
  },
  created() {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
        this.getAllCompanyList();
        this.init();
      });
    },
    // 查询公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        this.allCompanyList = res.data.data.list; // 公司结构集合
      });
    },
    // 选择的公司
    checkedCompany() {
      // 点击获取公司ID
      this.crtInfo.ciId = this.$refs.cascader.getCheckedNodes()[0].data.id;
      // // 选择后让选择框消失
      this.$refs.cascader.dropDownVisible = false;
    },

    init() {
      this.getCrtList();
    },
    goSelect() {
      this.$router.push("/Select");
    },
    //添加或者编辑CRT信息
    addOrEditCrtInfo() {
      if (!this.crtInfo.id) {
        //校验图片是否符合规范
        this.file;
        var aaa = this.beforeAvatarUpload(this.file) == true;

        if (this.crtInfo.name == "") {
          //提示信息
          this.$message({
            type: "warning",
            message: "CRT名称不能为空！",
          });
        } else if (JSON.stringify(this.file) == {}) {
          //提示信息
          this.$message({
            type: "warning",
            message: "请选择图片！",
          });
        } else if (this.beforeAvatarUpload(this.file) != true) {
          this.$message({
            type: "warning",
            message: "图片格式错误！",
          });
        } else {
          //实例化FormData对象用于传输图片文件和图片名称
          const formData = new FormData();
          formData.append("file", this.file);
          formData.append("name", this.crtInfo.name);
          formData.append("ciId", this.crtInfo.ciId);

          //调用接口进行添加CRT信息
          CRT.addCrtInfo(formData).then((res) => {
            //提示信息
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });
            //回到页面并刷新
            this.getCrtList();
            if (res.data.success) {
              this.dialogVisible = false;
            }
          });
        }
      } else {
        //实例化FormData对象用于传输图片文件和图片名称
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("name", this.crtInfo.name);
        formData.append("ciId", this.crtInfo.ciId);

        CRT.updateCrtInfo(this.crtInfo).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getCrtList();
          if (res.data.success) {
            this.dialogVisible = false;
          }
        });
      }
    },
    //编辑弹框
    handleEdit(row) {
      this.crtInfo = {
        id: row.id,
        name: row.name,
        url: row.url,
      };
      this.addOrEditFlag = "edit";
      this.dialogVisible = true;
    },
    //多条件分页查询CRT信息
    getCrtList() {
      CRT.getCrtList(this.current, this.limit, this.crtQuery).then((res) => {
        this.total = res.data.data.total;
        this.crtList = res.data.data.rows;

        for (let i = 0; i < this.crtList.length; i++) {
          this.crtList[i].ciId = this.companyNameById[this.crtList[i].ciId];
        }

        if (res.data.success) {
          this.loading = false;
        }
      });
    },
    //删除CRT信息
    removeCrtInfo(id) {
      this.$confirm(
        "此操作将永久删除该CRT以及该CRT绑定的点位, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        //点击确定，指定then方法
        CRT.removeCrtInfo(id).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getCrtList();
        });
      });
    },

    //清空设备类型信息对象
    clearCrtInfo() {
      this.crtInfo = {
        id: "",
        name: "",
        url: "",
        ciId: "",
      };
      this.addOrEditFlag = "add";
      this.getCrtList();
      this.imgFlag = false;
    },
    //返回上一级
    goBack() {
      window.history.back();
    },
    /****************************上传图片相关*************************************/
    //上傳设备类型图标文件选择后触发
    fileChange(e) {
      let files = e.target.files[0]; // 获取上传内容
      this.file = files;

      let fileReader = new FileReader();
      let type = "default";

      this.beforeAvatarUpload(files);
      //判断文件类型
      if (/image/.test(files.type)) {
        fileReader.readAsDataURL(files);
        type = "image";
      } else {
        fileReader.readAsText(files, "utf-8");
        type = "text";
      }
      //文件加载出错
      fileReader.onerror = function (err) {};
      fileReader.onload = () => {
        this.crtInfo.url = fileReader.result;
        this.imgFlag = true;
      };
    },
    //验证
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isSVG = file.type === "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 100;

      if (!(isJPG || isPNG || isSVG || isJPEG)) {
        this.file = {};
        document.getElementById("crtInfoPicture").value = "";
        this.$message.error("上传图标只能是 JPG、PNG或SVG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图标大小不能超过 100MB!");
        return (isJPG || isPNG || isSVG) && isLt2M;
      }

      return (isJPG || isPNG || isSVG || isJPEG) && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.crt .el-row {
  margin-bottom: 20px;
}

.crt .hide .el-upload--picture-card {
  display: none;
}

/deep/ .el-page-header__content {
  color: white;
}
</style>
