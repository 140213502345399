import request from '@/../utils/request'
const api_name = '/iotechserver/crtInfo'

export default {
    getAllCrt() {
        return request({
            url: `${api_name}/getAllCrt`,
            method: 'get'
        })
    },
    getCrtList(current, limit, CrtQuery) {
        return request({
            url: `${api_name}/pageCrtInfo/${current}/${limit}`,
            method: 'get',
            data: CrtQuery
        })
    },
    addCrtInfo(data) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            },
            url: `${api_name}/add`,
            method: 'post',
            data: data,
        })
    },
    removeCrtInfo(id) {
        return request({
            url: `${api_name}/${id}`,
            method: 'delete'
        })
    },
    updateCrtInfo(crtInfo) {
        return request({
            url: `${api_name}/edit`,
            method: 'post',
            data: crtInfo,
        })
    },
    getNoCoordinateCrtInfoList() {
        return request({
            url: `${api_name}/getNoCoordinateCrtInfoList`,
            method: 'get'
        })
    },
    getAllFloorCrt(id) {
        return request({
            url: `${api_name}/getAllFloorCrt?id=${id}`,
            method: 'get'
        })
    }


}